import { useState, MouseEvent, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { Dayjs } from "dayjs";
import {
  BasicSelectWithCheckBox,
  BasicButton,
  FilterIcon,
  DarkModeContext,
  BasicDatePicker,
  getEpochTime,
} from "@datwyler/shared-components";
import { ACTIVE } from "../../utils/constants";

const filterContainerStyle = {
  display: "inline-flex",
  height: "44px",
  marginLeft: "24px",
};

const Filters = (props) => {
  const {
    fetchDevices,
    sites = [],
    tenantId,
    devices = [],
    filterDefaults,
    setFilters,
  } = props;
  const [interimFilters, setInterimFilters] = useState(filterDefaults);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const [siteOptions, setSiteOptions] = useState([]);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [parameterOptions, setParameterOptions] = useState([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (sites) {
      const siteOptions = [];
      sites.forEach((site, index) => {
        siteOptions.push({
          priority: index,
          label: site?.name,
          value: site?.id,
        });
      });
      setSiteOptions(siteOptions);
      setInterimFilters(filterDefaults);
    }
  }, [sites]);

  useEffect(() => {
    if (devices.length > 0) {
      const deviceOptions = [];
      const deviceIds = []; // for checking if current filters exists
      devices.forEach((device, index) => {
        deviceOptions.push({
          priority: index,
          label: device.name,
          value: device.id,
        });
        deviceIds.push(device.id);
      });
      setDeviceOptions(deviceOptions);

      if (interimFilters.devices?.length > 0) {
        const newDevices = [];
        interimFilters.devices.forEach((d) => {
          if (deviceIds.includes(d)) {
            newDevices.push(d);
          }
        });
        setInterimFilters({ ...interimFilters, devices: newDevices });
      }
    }
  }, [devices]);

  const getParameterOptions = (selectedDevices) => {
    const parameterOptions = [];
    const checkedParameters = [];
    if (selectedDevices?.length > 0) {
      selectedDevices.forEach((deviceId) => {
        const device = devices.find((dev) => dev.id === deviceId);
        device?.telemetry?.forEach((t) => {
          if (!checkedParameters.includes(t.name)) {
            parameterOptions.push({
              priority: parameterOptions.length + 1,
              label: intl.formatMessage({ id: t.name }),
              value: t.name,
            });
            checkedParameters.push(t.name);
          }
        });
      });
    }
    setParameterOptions(parameterOptions);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSite = (sites: Array<string>) => {
    setInterimFilters({ ...interimFilters, sites: sites });
    if (sites.length === 0) {
      setInterimFilters({ ...interimFilters, devices: [], parameters: [] });
      return;
    }

    if (tenantId) {
      const filters = [`status:${ACTIVE}`];
      let sitesToSend = "";
      sites.forEach((site, index) => {
        if (index === 0) sitesToSend = sitesToSend + `siteId:` + site;
        else sitesToSend = sitesToSend + `|status:` + site;
      });
      if (sitesToSend) filters.push(sitesToSend);
      fetchDevices({
        variables: {
          tenantId: tenantId,
          page: 0,
          size: 999999,
          filter: filters,
        },
      });
    }
  };

  const handleChangeDevice = (devices: Array<string>) => {
    setInterimFilters({ ...interimFilters, devices: devices });
    getParameterOptions(devices);
  };

  const handleChangeParameter = (parameters: Array<string>) => {
    setInterimFilters({ ...interimFilters, parameters: parameters });
  };

  const applyFilters = () => {
    setFilters(interimFilters);
    handleClose();
  };

  const resetFilters = () => {
    setInterimFilters(filterDefaults);
  };

  const handleChangeStartDate = (startDate) => {
    const startTime = getEpochTime(startDate);
    setStartDate(startDate);
    setInterimFilters({ ...interimFilters, startTime: startTime });
  };

  return (
    <Box>
      <Box sx={{ paddingTop: "16px", display: "flex", alignItems: "center" }}>
        <Box sx={filterContainerStyle}>
          <BasicButton
            variant="outlined"
            onClick={handleClick}
            sx={{ border: "1px solid " + colors.blueGray300 }}
          >
            <FilterIcon fill={colors.iconColor} />
            <Typography
              sx={{
                fontFamily: "NotoSans-Medium",
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: 0,
                lineHeight: "20px",
                marginLeft: "10px",
              }}
            >
              {intl.formatMessage({ id: "filters" })}
            </Typography>
          </BasicButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              marginTop: "10px",
            }}
            PaperProps={{
              sx: {
                boxShadow: "0 2px 8px 0 rgba(16,24,40,0.16)",
                borderRadius: "8px",
                backgroundColor: colors.selectDropDownBg,
              },
            }}
          >
            <Box sx={{ padding: "16px" }}>
              <Box sx={{ width: "230px" }}>
                <BasicSelectWithCheckBox
                  onChange={handleChangeSite}
                  values={interimFilters.sites}
                  label={intl.formatMessage({ id: "sites" })}
                  menuItems={siteOptions}
                />
              </Box>
              {interimFilters.sites?.length > 0 && (
                <>
                  <Box sx={{ width: "230px", marginTop: "16px" }}>
                    <BasicSelectWithCheckBox
                      onChange={handleChangeDevice}
                      values={interimFilters.devices}
                      label={intl.formatMessage({ id: "device" })}
                      menuItems={deviceOptions}
                    />
                  </Box>
                  <Box sx={{ width: "230px", marginTop: "16px" }}>
                    <BasicSelectWithCheckBox
                      onChange={handleChangeParameter}
                      values={interimFilters.parameters}
                      label={intl.formatMessage({ id: "parameter" })}
                      menuItems={parameterOptions}
                    />
                  </Box>
                </>
              )}
              <Box sx={{ width: "230px", marginTop: "16px" }}>
                <BasicDatePicker
                  value={startDate}
                  handleOnChange={handleChangeStartDate}
                />
              </Box>
              <Box
                sx={{ width: "230px", marginTop: "16px", textAlign: "right" }}
              >
                <BasicButton
                  variant={"outlined"}
                  onClick={resetFilters}
                  sx={{
                    padding: "12px 16px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "NotoSans-Regular",
                      fontSize: "14px",
                      letterSpacing: 0,
                      lineHeight: "20px",
                    }}
                  >
                    {intl.formatMessage({ id: "reset" })}
                  </Typography>
                </BasicButton>
                {interimFilters.devices.length > 0 &&
                  interimFilters.parameters.length > 0 &&
                  interimFilters.startTime && (
                    <BasicButton
                      variant={"contained"}
                      onClick={applyFilters}
                      sx={{ marginLeft: "20px", padding: "12px 16px" }}
                    >
                      {intl.formatMessage({ id: "apply" })}
                    </BasicButton>
                  )}
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>
    </Box>
  );
};

export default Filters;
